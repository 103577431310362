import React from "react";
import { InputFormProps } from "./interface/formInterface";
import DivStyle from "../style/div.component";

const FormCafeCheckInput = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  return (
    <DivStyle
      display="flex"
      flex_direction="row"
      align_items="center"
      width={width}
    >
      <input
        checked={value.value1}
        onChange={(e) => {
          if (e.target.checked && value.value2) {
            value.setValue2(false);
          }
          if (e.target.checked && value.value3) {
            value.setValue3(false);
          }

          setValue(e.target.checked);
        }}
        type="checkbox"
        id="check"
        style={{ width: "20px", height: "20px" }}
      ></input>
      <label
        htmlFor="check"
        style={{
          color: "white",
          marginLeft: "7px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        {label}
      </label>
    </DivStyle>
  );
};
export default FormCafeCheckInput;
