import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import API from "../../api/api";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
interface IReport {
  id: number;
  createdAt: string;
  cafeId: number;
  info: string;
  userId: number;
  nickname: string;
  pubName: string;
}
const PubReport = () => {
  const [dataList, setDataList] = useState<IReport[]>([]);
  const [searchDataList, setSearchDataList] = useState<IReport[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [cafeId, setCafeId] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [info, setInfo] = useState("");

  const headers = [
    { text: "펍 ID", align: "center", sortable: false, value: "cafeId" },
    { text: "펍", align: "center", sortable: false, value: "pubName" },
    { text: "내용", align: "center", sortable: false, value: "info" },
    { text: "닉네임", align: "center", sortable: false, value: "nickname" },
    {
      text: "신고 날짜",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];

  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: cafeId,
        setValue: setCafeId,
        label: "펍ID",
        width: "100%",
        type: "input-dash",
      },
      {
        value: info,
        setValue: setInfo,
        label: "내용",
        width: "100%",
        type: "input-dash",
      },
      {
        value: createdAt,
        setValue: setCafeId,
        label: "신고 날짜",
        width: "100%",
        type: "input-dash",
      },
    ],
  };

  const setDetail = (item: IReport) => {
    setCafeId(item.cafeId);
    setInfo(item.info);
    setCreatedAt(item.createdAt);
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
    if (!search.trim()) {
      setSearchDataList(dataList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(dataList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((data: IReport) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(data).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getReports = async () => {
    const res = await AdminAPI.getReportList();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IReport) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);

      setDataList(updatedData);
      setSearchDataList(updatedData);
    }
  };

  useEffect(() => {
    getReports();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          허위신고 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => handleSearch(e.target.value)}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchDataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchDataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm form={formData.form} title={formData.title} />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default PubReport;
