import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import Form from "../../components/form/form";
import API from "../../api/api";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { IFaq } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const Faq = () => {
  const [noticeList, setNoticeList] = useState<IFaq[]>([]);
  const [searchNoticeList, setSearchNoticeList] = useState<IFaq[]>([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");

  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "공지제목",
        width: "100%",
        type: "input",
      },
      {
        value: description,
        setValue: setDescription,
        label: "설명",
        width: "100%",
        type: "text",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "타이틀",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "등록일시",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "수정일시",
      align: "center",
      sortable: false,
      value: "updatedAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(0);
    setTitle("");
    setDescription("");
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    const body = {
      title,
      description,
    };

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);

      const res = await AdminAPI.registerFaq(body);

      if (res) {
        alert("신규 등록 되었습니다.");

        getFaqList();

        addForm();
      }

      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);

      const res = await AdminAPI.removeFaq(id);

      if (res && res.status === 200) {
        alert("삭제 되었습니다.");

        getFaqList();

        addForm();
      }

      setIsLoading(false);
    }
  };

  const save = async () => {
    const body = {
      id,
      title,
      description,
    };

    setIsLoading(true);

    const res = await AdminAPI.updateFaq(body);

    if (res && res.status === 200) {
      alert("저장 되었습니다.");

      getFaqList();

      addForm();
    }

    setIsLoading(false);
  };

  const detail = (item: IFaq) => {
    setMode("U");
    setId(item.id);
    setTitle(item.title);
    setDescription(item.description);
  };

  const getFaqList = async () => {
    const res = await API.getFaqList();

    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IFaq) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
        updatedAt: formatDate(item.updatedAt),
      }));

      const totalPages = Math.ceil(updatedData.length / 10);

      setTotalPages(totalPages);
      setNoticeList(updatedData);
      setSearchNoticeList(updatedData);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    if (!search.trim()) {
      setSearchNoticeList(noticeList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(noticeList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = noticeList.filter((item: IFaq) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchNoticeList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          자주하는 질문 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchNoticeList}
            setDetail={detail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchNoticeList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <Form
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Faq;
