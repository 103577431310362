import { adminApiRequest } from "../../utils/apiUtils";

export const NoticeAPI = {
  updateNotice: (body: any) => {
    return adminApiRequest.put(`/notice/${body.id}`, body).then((res) => res);
  },

  removeNotice: (id: number) => {
    return adminApiRequest.delete(`/notice/${id}`).then((res) => res);
  },

  registerNotice: (body: any) => {
    return adminApiRequest.post("/notice", body).then((res) => res);
  },

  updateSecondHandMarketNotice: (body: any) => {
    return adminApiRequest
      .put("/second-hand-market-notice", body)
      .then((res) => res);
  },

  removeSecondHandMarketNotice: (id: number) => {
    return adminApiRequest
      .delete(`/second-hand-market-notice?id=${id}`)
      .then((res) => res);
  },

  registerSecondHandMarketNotice: (body: any) => {
    return adminApiRequest
      .post("/second-hand-market-notice", body)
      .then((res) => res);
  },
};
