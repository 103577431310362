import { adminApiRequest } from "../../utils/apiUtils";

export const FaqAPI = {
  updateFaq: (body: any) => {
    return adminApiRequest.put(`/faq/${body.id}`, body).then((res) => res);
  },

  removeFaq: (id: number) => {
    return adminApiRequest.delete(`/faq/${id}`).then((res) => res);
  },

  registerFaq: (body: any) => {
    return adminApiRequest.post("/faq", body).then((res) => res);
  },
};
