import DivStyle from "../../components/style/div.component";
import SmallForm from "../../components/form/small_form";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import API from "../../api/api";
import { IYoutube } from "./interface";
import ButtonStyle from "../../components/style/button.component";
import YoutubeTable from "../../components/table/YoutubeTable";
import { YoutubeChannelResponse } from "../youtubeChannel/interface";

const Youtube = () => {
  const [searchYoutubeList, setSearchYoutubeList] = useState<IYoutube[]>([]);
  const [youtubeChannelList, setYoutubeChannelList] = useState<
    YoutubeChannelResponse[]
  >([]);
  const [channel, setChannel] = useState<YoutubeChannelResponse | null>(null);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [mode, setMode] = useState("V");
  const [isRecommend, setIsRecommend] = useState(false);

  const [id, setId] = useState(0);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [ord, setOrd] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [type, setType] = useState("YOUTUBE");

  function extractYouTubeId(url: string) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const getThumnail = (url: string) => {
    if (url && url !== "") {
      const videoId = extractYouTubeId(url);
      setThumbnailUrl(
        `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      );
    }
  };

  const formData: FormProps = {
    title: type,
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "영상 제목",
        type: "input",
        width: "100%",
      },
      {
        value: {
          value1: url,
          getThumnail,
        },
        setValue: setUrl,
        label: "영상 URL",
        type: "input-button",
        width: "100%",
      },
      {
        value: channel,
        setValue: setChannel,
        options: youtubeChannelList,
        label: "채널",
        width: "100%",
        type: "category",
      },
      {
        value: isRecommend,
        setValue: setIsRecommend,
        label: "추천 영상",
        type: "check",
        width: "100%",
      },
      {
        value: thumbnailUrl,
        setValue: setThumbnailUrl,
        label: "썸네일 URL",
        type: "input-dash",
        width: "100%",
      },
    ],
  };

  const headers = [
    {
      text: "순번",
      align: "center",
      sortable: false,
      value: "ord",
    },
    {
      text: "제목",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "생성일",
      align: "start",
      sortable: false,
      value: "createdAt",
    },
  ];

  const setDetail = async (item: IYoutube) => {
    setMode("U");
    setId(item.id);
    setTitle(item.title);
    setUrl(item.url);
    setOrd(item.ord);
    setThumbnailUrl(item.thumbnailUrl ? item.thumbnailUrl : "");
    setIsRecommend(item.recommend ? item.recommend : false);
    setChannel(item.youtubeChannel);
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setUrl("");
    setTitle("");
    setOrd(0);
    setThumbnailUrl("");
    setIsRecommend(false);
    setChannel(null);
  };
  const save = async () => {
    const body = {
      id,
      url,
      title,
      ord,
      type,
      thumbnailUrl,
      isRecommend,
      channelId: channel?.id,
    };

    if (window.confirm("저장 하시겠습니까?")) {
      setIsLoading(true);
      const res_meta = await AdminAPI.updateYoutube(body);
      if (res_meta && res_meta.status === 200) {
        alert("저장되었습니다.");
        addForm();
        getYoutube();
      }
    }
    setIsLoading(false);
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeYoutube(id);
      if (res && res.status === 200) {
        alert("삭제 되었습니다.");
        getYoutube();
        addForm();
      }
    }
    setIsLoading(false);
  };
  const add = async () => {
    const body = {
      title,
      url,
      ord: 1,
      type,
      thumbnailUrl,
      isRecommend,
      channelId: channel?.id,
    };

    setIsLoading(true);
    const res = await AdminAPI.registerYoutube(body);
    if (res) {
      alert("신규 등록이 되었습니다.");
      getYoutube();
      addForm();
    }

    setIsLoading(false);
  };

  const getYoutube = async () => {
    const res = await API.getYoutubeList(type);
    if (res && res.status === 200) {
      setSearchYoutubeList(res.data);
    }
  };
  useEffect(() => {
    getYoutube();
  }, [type]);

  const getYoutubeChannel = async () => {
    const res = await API.getYoutubeChannelList();
    if (res && res.status === 200) {
      setYoutubeChannelList(res.data);
    }
  };
  useEffect(() => {
    getYoutubeChannel();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <DivStyle
          width="100%"
          height="auto"
          display="flex"
          flex_direction="row"
          align_items="center"
          justify_content="center"
          marginTop="40px"
        >
          <ButtonStyle
            backgroundColor={type === "YOUTUBE" ? "#1976D2" : "black"}
            color="white"
            border="none"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={() => {
              setType("YOUTUBE");
              addForm();
            }}
          >
            러너 영상 관리
          </ButtonStyle>
          <ButtonStyle
            backgroundColor={type === "CARTOON" ? "#1976D2" : "black"}
            color="white"
            border="none"
            style={{ fontSize: "18px", fontWeight: "bold" }}
            onClick={() => {
              setType("CARTOON");
              addForm();
            }}
          >
            만화 관리
          </ButtonStyle>
        </DivStyle>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <YoutubeTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchYoutubeList}
            setDetail={setDetail}
            setDataList={setSearchYoutubeList}
            addForm={addForm}
            getDatas={getYoutube}
            type={type}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Youtube;
