import { apiRequest } from "../../utils/apiUtils";

export const noticeApi = {
  getPopupList: () =>
    apiRequest<any>("get", "/popup-notice/list", { fullResponse: true }),
  getNoticeList: () =>
    apiRequest<any>("get", "/notice", { fullResponse: true }),
  getFaqList: () => apiRequest<any>("get", "/faq", { fullResponse: true }),
  getEventBanner: () =>
    apiRequest<any>("get", "/runner-event/list", { fullResponse: true }),
};
