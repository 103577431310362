import { adminApiRequest } from "../../utils/apiUtils";

export const YoutubeAPI = {
  sortYoutube: (body: any) => {
    return adminApiRequest.post("/youtube/sort", body).then((res) => res);
  },

  sortYoutubeChannel: (body: any) => {
    return adminApiRequest
      .post("/youtube-channel/sort", body)
      .then((res) => res);
  },

  removeYoutube: (id: number) => {
    return adminApiRequest.delete(`/youtube/delete/${id}`).then((res) => res);
  },

  removeYoutubeChannel: (id: number) => {
    return adminApiRequest
      .delete(`/youtube-channel/delete/${id}`)
      .then((res) => res);
  },

  updateYoutube: (body: any) => {
    return adminApiRequest
      .put(`/youtube/modify/${body.id}`, body)
      .then((res) => res);
  },

  updateYoutubeChannel: (body: any) => {
    return adminApiRequest
      .put(`/youtube-channel/modify/${body.id}`, body)
      .then((res) => res);
  },

  registerYoutube: (body: any) => {
    return adminApiRequest.post("/youtube/register", body).then((res) => res);
  },

  registerYoutubeChannel: (body: any) => {
    return adminApiRequest
      .post("/youtube-channel/register", body)
      .then((res) => res);
  },
};
