import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import { FormProps } from "../../components/form/interface/formInterface";
import ButtonStyle from "../../components/style/button.component";
import { Header } from "../../components/table/interface/interface";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import API from "../../api/api";
import { ICafe, IFacilities } from "./interface/interface";
import SmallForm from "../../components/form/small_form";
import AdminAPI from "../../api/admin";
import SearchSeller from "../../components/dialog/SearchSeller";
import SearchAddress from "../../components/dialog/SearchAddress";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import CafePage from "../../components/table/cafePaging";
import CafePagingTable from "../../components/table/CafePagingTable";

const Cafe = () => {
  const [cafeList, setCafeList] = useState<ICafe[]>([]);
  const [searchCafeList, setSearchCafeList] = useState<ICafe[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");
  const [selectedFile, setSelectedFile] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const [cafeId, setCafeId] = useState(0);
  const [cafeName, setCafeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerUserId, setOwnerUserId] = useState(0);
  const [employerId, setEmployerId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [vcn, setVcn] = useState("");
  const [email, setEmail] = useState("");
  const [oldAddress, setOldAddress] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [areaProvinceId, setAreaProvinceId] = useState(0);
  const [areaCityId, setAreaCityId] = useState(0);
  const [areaStreetId, setAreaStreetId] = useState(0);
  const [detailAddress, setDetailAddress] = useState("");
  const [lat, setLat] = useState<number | null>(null);
  const [lon, setLon] = useState<number | null>(null);
  const [images, setImages] = useState<{ id: number; imageUrl: string }[]>([]);
  const [facilities, setFacilities] = useState<string[]>([]);
  const [directions, setDirection] = useState("");
  const [informationUse, setInformationUse] = useState("");
  const [isAffiliated, setIsAffiliated] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isFranchise, setIsFranchise] = useState(false);
  const [isRecommendedTemp, setIsRecommendedTemp] = useState<boolean>(false);

  const [operatingDays, setOperatingDays] = useState([]);
  const [operatingStartTime, setOperatingStartTime] = useState("");
  const [operatingEndTime, setOperatingEndTime] = useState("");
  const [cafeEvents, setCafeEvents] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);
  const [prize, setPrize] = useState<number | null>(null);
  const [prizeMax, setPrizeMax] = useState<number | null>(null);
  const [buyIn, setBuyIn] = useState(0);
  const [buyInMax, setBuyInMax] = useState(0);
  const [blindUp, setBlindUp] = useState(0);
  const [blindUpMax, setBlindUpMax] = useState(0);
  const [operatingHours, setOperatingHours] = useState("");
  const [openChatURL, setOpenChatURL] = useState("");

  const [IsEmployerOpen, setIsEmployerOpen] = useState(false);
  const [IsLocationOpen, setIsLocationOpen] = useState(false);
  const [IsPremiumOpen, setIsPremiumOpen] = useState(false);

  const [areaProvinces, setAreaProvinces] = useState([]);
  const [areaCities, setAreaCities] = useState([]);
  const [areaStreets, setAreaStreets] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");

  const [allDay, setAllDay] = useState(false);

  const [getPubType, setGetPubType] = useState<string | null>(null);

  const [closeAt, setCloseAt] = useState("");
  const [pushCount, setPushCount] = useState(0);

  const setIsLoading = useSetRecoilState(loadingState);

  const addForm = async () => {
    setMode("I");
    setDeleteImage([]);
    setSelectedFile([]);
    setCafeId(0);
    setCafeName("");
    setOwnerName("");
    setOwnerUserId(0);
    setIsAffiliated(false);
    setNewAddress("");
    setOldAddress("");
    setEmployerId("");
    setPhoneNumber("");
    setVcn("");
    setEmail("");
    setAreaCityId(0);
    setAreaProvinceId(-1);
    setAreaStreetId(0);
    setDetailAddress("");
    setLat(0);
    setLon(0);
    setImages([]);
    setFacilities([]);
    setDirection("");
    setInformationUse("");
    setIsRecommended(false);
    setIsPremium(false);
    setAreaCities([]);
    setAreaStreets([]);
    setAreaProvinces([]);
    setOperatingDays([]);
    setOperatingStartTime("");
    setOperatingEndTime("");
    setBlindUp(0);
    setBlindUpMax(0);
    setBuyIn(0);
    setBuyInMax(0);
    setPrize(null);
    setPrizeMax(null);
    setBlindUpMax(0);
    setCafeEvents([]);
    setGameTypes([]);
    setOpenChatURL("");
    const area_res = await API.getAreaProvince();
    if (area_res && area_res.status === 200) {
      setAreaProvinces(area_res.data);
    }
  };

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: cafeName,
        setValue: setCafeName,
        label: "펍명",
        width: "100%",
        type: "input",
      },
      {
        value: ownerName,
        setValue: setOwnerName,
        label: "대표자",
        width: "100%",
        isOpen: IsEmployerOpen,
        setIsOpen: setIsEmployerOpen,
        type: "search-group",
      },

      {
        type: "emp-form",
        value: {
          value1: employerId,
          value2: isAffiliated,
          value3: isPremium,
          value4: isRecommended,
          value5: isFranchise,
          setValue2: setIsAffiliated,
          setValue3: setIsPremium,
          setValue4: setIsRecommended,
          setValue5: setIsFranchise,
        },
        setValue: setEmployerId,
        label: "사업자 등록번호(-를 포함시켜주세요.)",
        width: "100%",
      },
      ...(isAffiliated || isFranchise
        ? [
            {
              value: {
                pushCount,
                cafeId,
              },
              setValue: setPushCount,
              label: "푸시 남은 횟수",
              width: "100%",
              type: `push-count`,
            },
            {
              value: closeAt,
              setValue: setCloseAt,
              label: "종료일시",
              width: "100%",
              type: "date",
            },
          ]
        : []),
      {
        type: "phone-form",
        value: {
          value1: phoneNumber,
          value2: vcn,
          label2: "안심번호",
        },
        setValue: setPhoneNumber,
        label: "연락처",
        width: "100%",
      },
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        width: "100%",
        type: "input",
      },
      {
        value: openChatURL,
        setValue: setOpenChatURL,
        label: "오픈채팅방 URL",
        width: "100%",
        type: "input",
      },
      {
        value: {
          value1: areaProvinceId,
          value2: areaCityId,
          value3: areaStreetId,
          setValue2: setAreaCityId,
          setValue3: setAreaStreetId,
          options2: areaCities,
          options3: areaStreets,
          setOptions2: setAreaCities,
          setOptions3: setAreaStreets,
          label2: "군/구",
          label3: "동",
        },
        setValue: setAreaProvinceId,
        label: "시/도",
        width: "100%",
        type: "area-form",
        options: areaProvinces,
      },
      {
        value: detailAddress,
        setValue: setDetailAddress,
        label: "상세주소",
        width: "100%",
        type: "input",
      },
      {
        value: {
          value1: lat,
          value2: lon,
          setValue: setLon,
          label2: "경도",
          setValue1: setLat,
          setValue2: setLon,
        },
        setValue: setLat,
        label: "위도",
        width: "100%",
        type: "location-form",
        isOpen: IsLocationOpen,
        setIsOpen: setIsLocationOpen,
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "사진",
        width: "100%",
        type: "files",
      },
      {
        value: {
          value1: images,
          setDeleteImage,
          cafeId,
        },
        setValue: setImages,
        label: "사진",
        width: "100%",
        type: "images-sort",
      },
      {
        value: facilities,
        setValue: setFacilities,
        label: "편의시설 및 서비스",
        width: "100%",
        type: "facility-form",
      },
      {
        value: informationUse,
        setValue: setInformationUse,
        label: "펍설명",
        width: "100%",
        height: "120px",
        type: "text",
      },
      {
        value: {
          value1: buyIn,
          value2: buyInMax,
          setValue2: setBuyInMax,
          label2: "최대 바이인",
        },
        setValue: setBuyIn,
        label: "바이인",
        width: "100%",
        type: "double-input",
      },
      {
        value: {
          value1: blindUp,
          value2: blindUpMax,
          setValue2: setBlindUpMax,
          label2: "최대 블라인드 업",
        },
        setValue: setBlindUp,
        label: "블라인드 업",
        width: "100%",
        type: "double-input",
      },
      {
        value: {
          value1: prize,
          value2: prizeMax,
          setValue2: setPrizeMax,
          label2: "최대 상금",
        },
        setValue: setPrize,
        label: "상금",
        width: "100%",
        type: "double-input",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "펍명",
      align: "center",
      sortable: false,
      value: "cafeName",
    },
    {
      text: "대표자",
      align: "center",
      sortable: false,
      value: "ownerName",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "주소",
      align: "center",
      sortable: false,
      value: "newAddress",
    },

    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "사업자등록번호",
      align: "center",
      sortable: false,
      value: "employerId",
    },
    {
      text: "등급",
      align: "center",
      sortable: false,
      value: "pubType",
    },
  ];

  const add = async () => {
    const pubType = isAffiliated
      ? "VIP"
      : isPremium
      ? "PREMIUM"
      : isFranchise
      ? "FRANCHISE"
      : "NORMAL";
    const body = {
      pubName: cafeName.replace(/[/r/n]/g, ""),
      ownerName,
      ownerUserId,
      employerId,
      email,
      areaProvinceId,
      areaCityId,
      areaStreetId,
      oldAddress,
      newAddress,
      detailAddress,
      lat,
      lon,
      directions,
      phoneNumber,
      vcn,
      informationUse,
      isAgree: true,
      facilities,
      isRecommended,
      pubType,
      cafeEvents: [],
      gameTypes: ["TOURNAMENT"],
      reviewCount: 0,
      reviewPoint: 0,
      likeCount: 0,
      operatingHours,
      operatingDays,
      operatingStartTime,
      operatingEndTime,
      openChatUrl: openChatURL,
      blindUp,
      blindUpMax,
      buyIn,
      buyInMax,
      prize,
      prizeMax,
    };

    const formData = new FormData();
    for (const img of selectedFile) {
      formData.append("images", img);
    }

    formData.append("cafe", JSON.stringify(body));

    if (window.confirm("신규 등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerCafe(formData);
        if (res) {
          if (res.status === 201) {
            alert("신규 등록 되었습니다.");
            getCafes();
            addForm();
          }
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
  };
  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (cafeId) {
        setIsLoading(true);
        const res = await AdminAPI.removeCafe(cafeId);
        if (res) {
          if (res.status === 200) {
            alert("삭제되었습니다.");
            getCafes();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };
  const save = async () => {
    const pubType = isAffiliated
      ? "VIP"
      : isPremium
      ? "PREMIUM"
      : isFranchise
      ? "FRANCHISE"
      : "NORMAL";
    if (pubType === "VIP" || pubType === "FRANCHISE") {
      if (!closeAt || closeAt === "") {
        alert("종료일을 입력해주세요");
        return;
      }
    }
    const body = {
      cafeId,
      pubName: cafeName,
      ownerName,
      ownerUserId,
      employerId,
      phoneNumber,
      oldAddress,
      newAddress,
      areaProvinceId,
      areaCityId,
      areaStreetId,
      detailAddress,
      directions,
      email,
      lat,
      lon,
      isAgree: true,
      isRecommended,
      vcn,
      cafeEvents,
      pubType,
      operatingHours,
      operatingDays,
      operatingStartTime,
      operatingEndTime,
      gameTypes: gameTypes.length > 0 ? gameTypes : ["TOURNAMENT"],
      facilities,
      blindUp,
      blindUpMax,
      buyIn,
      buyInMax,
      prize,
      prizeMax,
      informationUse,
      openChatUrl: openChatURL,
    };

    const deleteImages = {
      cafeId,
      deleteImageUrl: deleteImage,
    };

    const formData = new FormData();
    for (const img of selectedFile) {
      formData.append("addImageFiles", img);
    }

    formData.append("deleteImages", JSON.stringify(deleteImages));

    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);
      const res_image = await AdminAPI.updateCafeImages(formData);
      if (res_image) {
        if (res_image.status === 200) {
          try {
            const res = await AdminAPI.updateCafeMetaData(body);
            if (res) {
              if (res.status === 200) {
                if (
                  pubType === "PREMIUM" ||
                  pubType === "VIP" ||
                  pubType === "FRANCHISE"
                ) {
                  try {
                    const request = {
                      pubType,
                      pubId: cafeId,
                      closeAt: pubType === "VIP" ? closeAt : "2099-12-31T00:00",
                    };
                    await AdminAPI.createGrade(request);
                  } catch (e) {
                    alert("등급 수정에 실패했습니다.");
                    addForm();
                    getCafes();
                    setIsLoading(false);
                  }
                }
                alert("수정되었습니다.");
                addForm();
                getCafes();
              } else {
                setIsLoading(false);
                alert("수정에 실패하였습니다.");
              }
            } else {
              setIsLoading(false);
              alert("수정에 실패하엿습니다.");
            }
          } catch (e) {
            addForm();
            getCafes();
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          alert("수정에 실패하였습니다.");
        }
      }
      if (isRecommendedTemp) {
        if (!isRecommended) {
          await AdminAPI.exitRecommend(cafeId);
        }
      } else {
        if (isRecommended) {
          await AdminAPI.registerRecommend(cafeId);
        }
      }

      setIsLoading(false);
    }
  };
  const setDetail = async (item: ICafe) => {
    setMode("U");
    setDeleteImage([]);
    setSelectedFile([]);
    setCafeId(item.id);
    setCafeName(item.cafeName);
    setOwnerName(item.ownerName);
    setOwnerUserId(item.ownerUserId);
    setNewAddress(item.newAddress);
    setOldAddress(item.oldAddress);
    setEmployerId(item.employerId);
    setPhoneNumber(item.phoneNumber);
    setVcn(item.vcn);
    setEmail(item.email);
    setAreaCityId(item.areaCityId);
    setAreaProvinceId(item.areaProvinceId);
    setAreaStreetId(item.areaStreetId);
    setDetailAddress(item.detailAddress);
    setOpenChatURL(item.openChatUrl ? item.openChatUrl : "");
    setBlindUp(item.blindUp);
    setGameTypes(item.gameTypes);
    setCafeEvents(item.events);
    setLat(item.lat);
    setLon(item.lon);
    const imageUrls = item.images.map((image) => image.imageUrl);
    setImages(item.images);
    setPrize(item.prize);
    setPrizeMax(item.prizeMax);
    setBlindUpMax(item.blindUpMax);
    setBuyIn(item.buyIn);
    setBuyInMax(item.buyInMax);
    setOperatingHours(item.operatingHours ? item.operatingHours : "");
    setFacilities(item.facilities);
    setDirection(item.directions);
    setInformationUse(item.informationUse);
    setOperatingDays(item.operatingDays);
    setOperatingStartTime(item.operatingStartTime);
    setOperatingEndTime(item.operatingEndTime);
    setIsRecommended(item.recommended);
    setCloseAt("");
    setPushCount(0);
    if (
      item.pubType === "VIP" ||
      item.pubType === "PREMIUM" ||
      item.pubType === "FRANCHISE"
    ) {
      try {
        const res = await AdminAPI.getPubGradeCloseAt(item.id);
        setCloseAt(res.closeAt);
        setPushCount(res.pushCount);
      } catch (e) {}
    }
    if (item.pubType === "NORMAL") {
      setIsFranchise(false);
      setIsPremium(false);
      setIsAffiliated(false);
    }
    if (item.pubType === "FRANCHISE") {
      setIsFranchise(true);
    } else {
      setIsFranchise(false);
    }
    if (item.pubType === "VIP") {
      setIsAffiliated(true);
    } else {
      setIsAffiliated(false);
    }
    if (item.pubType === "PREMIUM") {
      setIsPremium(true);
    } else {
      setIsPremium(false);
    }
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getCafes = async (searchInput?: string) => {
    console.log(getPubType, search);

    const res = await API.getCafes(
      currentPage,
      getPubType,
      searchInput ? searchInput : search
    );
    if (res && res.status === 200) {
      const updatedData = res.data.pubDetailResponseList.map((item: ICafe) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setCafeList(updatedData);
      setSearchCafeList(updatedData);
    }

    const area_res = await API.getAreaProvince();
    if (area_res && area_res.status === 200) {
      setAreaProvinces(area_res.data);
    }
  };
  const handleSearch = async (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    getCafes(search);

    return;
  };
  useEffect(() => {
    getCafes();
  }, [getPubType, currentPage]);

  const exportToExcel = async (headers: Header[], items: ICafe[]) => {
    const today = new Date().toISOString().split("T")[0];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // 헤더 행 추가
    worksheet.addRow(headers.map((header) => header.text));

    // 데이터 행 추가
    items.forEach((item: ICafe | any) => {
      const rowValues = headers.map((header) => {
        if (header.value === "createdAt") {
          // createdAt 컬럼에서 날짜만 추출하여 사용
          return item[header.value].split("T")[0];
        } else {
          return item[header.value];
        }
      });
      worksheet.addRow(rowValues);
    });

    // 엑셀 파일 생성 및 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "all_report" + "_" + today + ".xlsx");
  };
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          펍 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <ButtonStyle
                fontWeight="700"
                backgroundColor="#1976D2"
                border="none"
                borderRadius="7px"
                marginTop="20px"
                width="85px"
                height="40px"
                color="white"
                marginLeft="20px"
                onClick={() => exportToExcel(headers, cafeList)}
              >
                TOEXCEL
              </ButtonStyle>
              <ButtonStyle
                fontWeight="700"
                backgroundColor={getPubType === null ? "#1976D2" : "gray"}
                border="none"
                borderRadius="7px"
                marginTop="20px"
                marginLeft="10px"
                color="white"
                width="85px"
                height="40px"
                onClick={() => setGetPubType(null)}
              >
                전체
              </ButtonStyle>
              <ButtonStyle
                fontWeight="700"
                backgroundColor={getPubType === "NORMAL" ? "#1976D2" : "gray"}
                border="none"
                borderRadius="7px"
                marginTop="20px"
                marginLeft="10px"
                color="white"
                width="85px"
                height="40px"
                onClick={() => setGetPubType("NORMAL")}
              >
                일반
              </ButtonStyle>
              <ButtonStyle
                fontWeight="700"
                backgroundColor={getPubType === "PREMIUM" ? "#1976D2" : "gray"}
                border="none"
                borderRadius="7px"
                marginTop="20px"
                marginLeft="10px"
                color="white"
                width="85px"
                height="40px"
                onClick={() => setGetPubType("PREMIUM")}
              >
                스탠다드
              </ButtonStyle>
              <ButtonStyle
                fontWeight="700"
                backgroundColor={getPubType === "VIP" ? "#1976D2" : "gray"}
                border="none"
                borderRadius="7px"
                marginTop="20px"
                marginLeft="10px"
                color="white"
                width="85px"
                height="40px"
                onClick={() => setGetPubType("VIP")}
              >
                프리미엄
              </ButtonStyle>
              <ButtonStyle
                fontWeight="700"
                backgroundColor={
                  getPubType === "FRANCHISE" ? "#1976D2" : "gray"
                }
                border="none"
                borderRadius="7px"
                marginTop="20px"
                marginLeft="10px"
                color="white"
                width="85px"
                height="40px"
                onClick={() => setGetPubType("FRANCHISE")}
              >
                가맹점
              </ButtonStyle>
            </div>
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearch(e.target.value);
                }}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>

          <CafePagingTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchCafeList}
            setDetail={setDetail}
          />
          <CafePage
            count={totalCount}
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchCafeList}
            setDataList={setCafeList}
            setSearchDataList={setSearchCafeList}
            setCurrentPage={setCurrentPage}
            isSearch={isSearch}
            search={search}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          flex_direction="row"
        >
          <DivStyle
            width="50%"
            display="flex"
            justify_content="flex-start"
          ></DivStyle>
          <DivStyle width="50%" display="flex" justify_content="flex-end">
            <ButtonStyle
              border="none"
              backgroundColor="#1976D2"
              width="85px"
              height="40px"
              color="white"
              borderRadius="5px"
              onClick={() => addForm()}
            >
              신규 등록
            </ButtonStyle>
          </DivStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
      {IsEmployerOpen ? (
        <SearchSeller
          setOwnerId={setOwnerUserId}
          setOwnerName={setOwnerName}
          setIsOpen={setIsEmployerOpen}
        />
      ) : null}
      {IsLocationOpen ? (
        <SearchAddress
          setNewAddress={setNewAddress}
          setOldAddress={setOldAddress}
          setIsOpen={setIsLocationOpen}
          setLat={setLat}
          setLon={setLon}
        />
      ) : null}
    </DivStyle>
  );
};

export default Cafe;
