/* eslint-disable */
import { useEffect, useState } from "react";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";

const CafePagingTable = (props: TableProps) => {
  const [isWide, setIsWide] = useState(window.innerWidth > 700);
  const { headers, dataList, setDetail, setIsOpen } = props;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setIsWide(false);
      } else {
        setIsWide(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getNestedData(data: any, path: string) {
    const keys = path.split(".");
    let result = data;
    for (let key of keys) {
      result = result[key];
      if (result === undefined) break;
    }
    return result;
  }
  return isWide ? (
    <TableStyle marginTop="30px">
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataList.map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
              if (setIsOpen) setIsOpen(true);
            }}
          >
            {headers.map((header) =>
              Array.isArray(data[header.value]) &&
              header.value === "rankers" ? (
                <td key={header.value}>{data[header.value].length}명</td>
              ) : typeof data[header.value] === "boolean" ? (
                data[header.value] === false ? (
                  <td key={header.value}>N</td>
                ) : (
                  <td key={header.value}>Y</td>
                )
              ) : header.value === "endDate" && data["endDate"] === null ? (
                <td key={header.value}>상시</td>
              ) : header.value === "pubType" ? (
                <td key={header.value}>
                  {data[header.value] === "NORMAL"
                    ? "FREE"
                    : data[header.value] === "PREMIUM"
                    ? "STANDARD"
                    : data[header.value] === "FRANCHISE"
                    ? "FRANCHISE"
                    : "PREMIUM"}
                </td>
              ) : header.value.includes(".") ? (
                <td key={header.value}>{getNestedData(data, header.value)}</td>
              ) : (
                <td key={header.value}>{data[header.value]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  ) : (
    <TableStyle setMobile={true} marginTop="30px">
      <tbody>
        {dataList.map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
              if (setIsOpen) setIsOpen(true);
            }}
          >
            {headers.map((header) => (
              <td key={header.value}>
                <div>{header.text}</div>
                {Array.isArray(data[header.value]) &&
                header.value === "rankers" ? (
                  <div>{data[header.value].length}명</div>
                ) : header.value === "endDate" && data["endDate"] === null ? (
                  <td key={header.value}>상시</td>
                ) : header.value.includes(".") ? (
                  <td key={header.value}>
                    {getNestedData(data, header.value)}
                  </td>
                ) : (
                  <td key={header.value}>{data[header.value]}</td>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default CafePagingTable;
