import { adminApiRequest } from "../../utils/apiUtils";

export const CafeAPI = {
  updateCafeImages: (formData: FormData) => {
    return adminApiRequest
      .put("/pub/update-images", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  updateCafeMetaData: (body: any) => {
    return adminApiRequest
      .put(`/pub/update-metadata/${body.cafeId}`, body)
      .then((res) => res);
  },

  registerCafe: (body: any) => {
    return adminApiRequest
      .post("/pub/register", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },
  getSellerPubCount: (userId: number) => {
    return adminApiRequest.get(`/cafe/seller/${userId}`).then((res) => res);
  },
  getPointLogs: () => {
    return adminApiRequest.get("/cafe/gamePointLogAllList").then((res) => res);
  },
  getReportList: () => {
    return adminApiRequest.get("/pub/report").then((res) => res);
  },
  sortCafeImages: (
    request: { id: number; displayOrder: number }[],
    id: number
  ) => {
    return adminApiRequest
      .put(`/pub/sort-images/${id}`, request)
      .then((res) => res);
  },

  removeCafe: (id: number) => {
    return adminApiRequest.delete(`/pub/exit/${id}`).then((res) => res);
  },
  exitRecommend: (id: number) => {
    return adminApiRequest
      .delete(`/cafe/recommend/exitByCafeIds?cafeIds=${id}`)
      .then((res) => res);
  },

  registerRecommend: (id: number) => {
    return adminApiRequest
      .post(`/cafe/recommend/register?cafeIds=${id}`, null)
      .then((res) => res);
  },

  updateRecommendList: (body: any) => {
    return adminApiRequest
      .put("/cafe/recommend/updateList", body)
      .then((res) => res);
  },

  removeRecommend: (ids: number[]) => {
    return adminApiRequest
      .delete(`/cafe/recommend/exitByIds?ids=${ids}`)
      .then((res) => res);
  },
};
