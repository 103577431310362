import { apiRequest } from "../../utils/apiUtils";

export const cafeApi = {
  getAllMessage: () =>
    apiRequest<any>("get", "/cafe/allMessageList", { fullResponse: true }),
  getCafes: (page: number, pubType: string | null, name?: string) =>
    apiRequest<any>("get", "/admin/pub/list", {
      params: { page, size: 10, pubType, name },
      fullResponse: true,
    }),
  getReportList: () =>
    apiRequest<any>("get", "/cafe/reportList", { fullResponse: true }),
  searchCafes: (name: string) =>
    apiRequest<any>("post", "/search/cafe", {
      data: { q: name },
      params: { q: name },
      fullResponse: true,
    }),

  searchCafe: (name: string) =>
    apiRequest<any>("get", "/search/cafe", {
      params: { q: name },
      fullResponse: true,
    }),
  getCafeRecommended: (id: number) =>
    apiRequest<any>("get", `/cafe/recommend/${id}`, { fullResponse: true }),
  getRecommendCafes: () =>
    apiRequest<any>("get", "/cafe/list/recommendCafes", { fullResponse: true }),
  getAllCafe: () =>
    apiRequest<any>("get", "/cafe/getAllCafeList", { fullResponse: true }),
  getCafeMessage: () =>
    apiRequest<any>("get", "/cafe/allMessageList", { fullResponse: true }),
};
