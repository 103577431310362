/* eslint-disable */
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import DivStyle from "../style/div.component";
import { PageProps } from "./interface/interface";
import { ICafe } from "../../pages/cafe/interface/interface";
import API from "../../api/api";
import AdminAPI from "../../api/admin";

const CafePage = (props: PageProps) => {
  const {
    currentPage,
    totalPages,
    setCurrentPage,
    setDataList,
    setSearchDataList,
    count,
    isSearch,
    search,
  } = props;

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getDataForPage = async (page: number) => {
    // let updatedData;
    // if (isSearch && search) {
    //   const res = await AdminAPI.searchCafes(search, page);
    //   if (res && res.status === 200) {
    //     updatedData = res.data.pubDetailResponseList.map((item: ICafe) => ({
    //       ...item,
    //       createdAt: formatDate(item.createdAt),
    //     }));
    //   }
    // } else {
    //   const res = await API.getCafes(page);
    //   if (res && res.status === 200) {
    //     updatedData = res.data.pubDetailResponseList.map((item: ICafe) => ({
    //       ...item,
    //       createdAt: formatDate(item.createdAt),
    //     }));
    //   }
    // }
    // if (setDataList) setDataList(updatedData);
    // if (setSearchDataList) setSearchDataList(updatedData);
  };
  return (
    <DivStyle
      width="auto"
      display="flex"
      fontSize="12px"
      align_items="center"
      marginTop="10px"
      marginRight="40px"
      justify_content="flex-end"
    >
      <p style={{ color: "white" }}>Total : {count}</p>
      <p style={{ color: "white", marginLeft: "15px" }}>
        {currentPage} of {totalPages > 0 ? totalPages : 1}
      </p>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "10px",
        }}
        onClick={() => {
          getDataForPage(currentPage - 1);
          setCurrentPage(currentPage - 1);
        }}
        disabled={currentPage === 1}
      >
        <Icon
          path={mdiChevronLeft}
          size={1}
          style={{
            color: currentPage === 1 ? "#626262" : "white",
          }}
        />
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "20px",
        }}
        onClick={() => {
          setCurrentPage(currentPage + 1);
          getDataForPage(currentPage + 1);
        }}
        disabled={currentPage >= totalPages}
      >
        <Icon
          path={mdiChevronRight}
          size={1}
          style={{
            color: currentPage >= totalPages ? "#626262" : "white",
          }}
        />
      </button>
    </DivStyle>
  );
};

export default CafePage;
